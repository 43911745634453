import axios from 'axios'
import React, { useEffect, useState } from 'react'
import no_image from '../assets/img/no_image_2.png'
import { API_SETTINGS } from '../settings/settings'
import PlacementsData from '../assets/static/placements.json'

const Placements = () => {
    const [placement, setPlacement] = useState(PlacementsData)
    const getPlacements = async () => {
        try {
            const res = await axios.get(API_SETTINGS.GET_ALL_PLACEMENTS);
            setPlacement(res.data)
        } catch (error) { }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getPlacements()
    }, [])

    return (
        <section className='placements' id='placements'>
            <div className="header-hight"></div>
            <div className="container">
                <div className="section-title">
                    <h2 className=''>Our Recent<span className='text-danger ms-1'>Placements</span></h2>
                    <p className=''><b>Signo Venture</b> supports you to get placed in top companies,whether you're trying to start a new career or change your existing one.</p>
                </div>

                <div className="placements-content">
                    <div className="row g-3">
                        {placement.map((place) => (
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12" key={place._id}>
                                <div className="card placements-card">
                                    <div className="card-body">
                                        <div className="row g-2">
                                            <div className="col-auto">
                                                <div className="placements-image border">
                                                    <img src={place.image} alt={place.name} onError={onImageError} />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="placements-title">
                                                    <h5 className=''>{place.name}</h5>
                                                    <p className=''><i className="fa-solid fa-briefcase"></i>{place.role}</p>
                                                    <p className=''><i className="fa-solid fa-building"></i>{place.company}</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <hr className='my-1' />
                                                <div className="placements-courses">
                                                    <p className='placements-course-title'>Courses Undertaken - <span>{place.course.title}</span></p>
                                                    <p className='placements-course-description'>{place.course.short_desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Placements