const env = process.env.REACT_APP_BASE_URL;
const ipify = process.env.REACT_APP_IP_IFY;
export const API_SETTINGS = {
    GET_ALL_HERO_SECTION: env + "herosections",
    GET_ALL_COURSES: env + "courses",
    GET_COURSES_BY_DOMAIN: env + "domain/courses",
    CURRENT_USER: env + "me",
    USER_COURSES: env + "user/courses",
    USER_COURSE_DETAILS: env + "user/course/details",
    GET_ALL_CAREERS: env + "career-paths",
    CREATE_FREE_SECTION: env + "freesection",
    GET_ALL_COMPANIES: env + "companies",
    GET_ALL_PLACEMENTS: env + "placements",
    SUBSCRIBED_NEWSLETTER: env + "subscribed-newsletter",
    GET_ALL_TESTIMONIALS: env + "testimonials",
    GET_CONTACTS: env + "social-info",
    GET_COURSE: env + "courses",
    SIGN_UP: env + "sign-up",
    SIGN_IN: env + "sign-in",
    VERIFY_OTP: env + "verify-otp",
    USER_UPDATE: env + "update-user",
    GET_COUNTUPS: env + "countups",
    VIEW_COUNTERS: env + "view-counters",
    VERIFY_EMAIL: env + "verifyemail",
    STUDENTS_LIST: env + "dashboard/students-list",
    IP_IFY: ipify + '?format=json',
};