import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import userDetailsData from '../../assets/static/userDetails.json'
import editButtons from '../../assets/static/editButtons.json'
import ViewProfile from '../../components/ViewProfile';
import EditPersonalProfile from '../../components/EditPersonalProfile';
import EditEducationProfile from '../../components/EditEducationProfile';
import EditResumeProfile from '../../components/EditResumeProfile';
import { getUserProfile } from '../../services/services';

const Profile = () => {
  const [selectedTab, setSeletedTab] = useState('view')
  const [userDetails, setUserDetails] = useState(userDetailsData)
  const navigate = useNavigate()

  const changeView = (type) => {
    if (selectedTab === type) {
      setSeletedTab('view')
    } else {
      setSeletedTab(type)
    }
  }


  useEffect(() => {
    const getUserDetails = async () => {
      try {

        const scopes = [
          "first_name",
          "last_name",
          "email",
          "mobile",
          "date_of_birth",
          "gender",
          "bio",
          "status",
          "image",
          "cover",
          "address",
          "resume",
          "education",
          "skills",
          "languages",
          "experiences",
          "career",
          "created_at",
        ]
        const resData = await getUserProfile(scopes)
        setUserDetails(resData.data)

      } catch (error) {
        if (error.response.status === 401) {
          navigate('/')
          sessionStorage.clear()
          toast.error(`Oops! ${error.response.data.message}!`)
        }
      }
    }
    getUserDetails()
  }, [navigate, selectedTab])

  return (
    <section className="profile">
      <div className="">
        <div className="card border-0 my-3">
          <div className="card-body">
            {editButtons.map((btns) => (
              <button className={selectedTab === btns.name ? btns.active : btns.class} key={btns.name} onClick={() => changeView(btns.name)}>
                <i className="fa-solid fa-pen-to-square me-2"></i>{btns.alias}
              </button>
            ))}
          </div>
        </div>
        <div className="row g-3">
          {selectedTab === 'view' && userDetails.email &&
            <ViewProfile data={userDetails} status={changeView}></ViewProfile>
          }

          {selectedTab === 'personal' && userDetails.email &&
            <EditPersonalProfile data={userDetails} status={changeView}></EditPersonalProfile>
          }

          {selectedTab === 'education' && userDetails.email &&
            <EditEducationProfile data={userDetails} status={changeView}></EditEducationProfile>
          }

          {selectedTab === 'resume' && userDetails.email &&
            <EditResumeProfile data={userDetails} status={changeView}></EditResumeProfile>
          }
        </div>
      </div>
    </section >
  )
}

export default Profile