import { Route, Routes } from "react-router-dom";
import VerifyEmail from "./components/VerifyEmail";
import Layout from "./Layout";
import Course from "./pages/Course";
import Courses from "./pages/Courses";
import { DashboardCourse } from "./pages/Dashboard/Course";

import Dashboard from "./pages/Dashboard/Dashboard";
import HomePage from "./pages/HomePage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";

const HomePageComponent = Layout(HomePage);
const DashboardComponent = Layout(Dashboard);
const DashboardCourseComponent = Layout(DashboardCourse);
const CourseComponent = Layout(Course);
const CoursesComponent = Layout(Courses);

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePageComponent />}></Route>
        <Route path='/course/:id' element={<CourseComponent />}></Route>
        <Route path='/courses' element={<CoursesComponent />}></Route>

        <Route element={<PrivateRoutes />}>
          <Route path='dashboard' element={<DashboardComponent />}></Route>
          <Route path='dashboard/course/:id' element={<DashboardCourseComponent />}></Route>
        </Route>

        <Route element={<PublicRoutes />}>
          <Route path='/sign-in' element={<SignIn></SignIn>}></Route>
          <Route path='/sign-up' element={<SignUp />}></Route>
          <Route path='/verifyemail' element={<VerifyEmail></VerifyEmail>}></Route>
        </Route>

        <Route path='*' element={<HomePageComponent />}></Route>
      </Routes>
    </>
  );
}

export default App;
