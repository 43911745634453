import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {
    const accessToken = sessionStorage.getItem('accessToken')

    return (
        accessToken ? <Outlet /> : <Navigate to={'/sign-in'} />
    )
}

export default PrivateRoutes