import axios from "axios";
import { API_SETTINGS } from "../settings/settings";

export const getTokenHeaders = () => {
  const token = sessionStorage.getItem("accessToken");
  return {
    accessToken: token,
    config: {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    },
  };
};

export const getAuthorization = () => {
  const token = sessionStorage.getItem("accessToken");
  return `Bearer ${token}`;
};

export const getUserProfile = async (scopes) => {
  const data = {
    scopes,
  };
  var config = {
    method: "POST",
    url: API_SETTINGS.CURRENT_USER,
    headers: {
      Authorization: getAuthorization(),
      "Content-Type": "application/json",
    },
    data: data,
  };
  return await axios(config);
};

export const getUserCourses = async () => {
  var config = {
    method: "GET",
    url: API_SETTINGS.USER_COURSES,
    headers: {
      Authorization: getAuthorization(),
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const getCourseDeatils = async (id) => {
  var config = {
    method: "GET",
    url: `${API_SETTINGS.USER_COURSE_DETAILS}?id=${id}`,
    headers: {
      Authorization: getAuthorization(),
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const VerifyEmailAddress = async (code) => {
  return await axios.get(`${API_SETTINGS.VERIFY_EMAIL}${code}`);
};

export const getAllStudents = async () => {
  const { config } = getTokenHeaders();
  return await axios.get(API_SETTINGS.STUDENTS_LIST, config);
};
