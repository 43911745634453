import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_SETTINGS } from '../settings/settings'

const EditEducationProfile = (compData) => {
    const data = compData.data
    const navigate = useNavigate()
    let [addEducation, setAddEducation] = useState([{
        collage: "",
        branch: "",
        year: ""
    }])

    const eduAddMore = (type, index) => {
        if (type === 'add') {
            setAddEducation([...addEducation, {
                collage: "",
                branch: "",
                year: ""
            }])
        }
        if (type === 'delete') {
            let eduArray = [...addEducation]
            eduArray.splice(index, 1);
            setAddEducation(eduArray)
        }
    }

    const handleChange = (e, i) => {
        let newFormValues = [...addEducation];
        newFormValues[i][e.target.name] = e.target.value;
        setAddEducation(newFormValues);
    }

    const submitEditForm = async (e) => {
        e.preventDefault();
        let submitData = []
        let fieldStatus = false
        addEducation.forEach(e => {
            if (e.collage && e.branch && e.year) {
                submitData.push(e)
            } else {
                fieldStatus = true
            }
        });
        if (fieldStatus) {
            toast.error(`Oops! Some required* fields are empty}!`)
            return
        }
        const accessToken = sessionStorage.getItem('accessToken')
        if (accessToken && submitData.length) {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
            let body = {
                email: data.email,
                update_values: {
                    education: submitData
                }
            }
            const res = await axios.patch(API_SETTINGS.CURRENT_USER, body, config);
            toast.success(res.data.message)
            compData.status('view')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        if (data.education.length) {
            setAddEducation(data.education)
        }
    }, [data.education])

    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <form className='' onSubmit={submitEditForm}>
                <div className="card border-0">
                    <div className="card-header bg-white border-0 py-3">
                        <h5 className='m-0'>Edit Education Info</h5>
                    </div>
                    <div className="card-body">
                        {addEducation.map((edu, index) => (
                            <div className="row g-2" key={index}>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <div className="mb-xl-3 mb-lg-3 mb-md-3 mb-0">
                                        {index === 0 &&
                                            <label htmlFor="collage" className='fw-500 mb-1'>University or College Name<span className='text-danger'> *</span></label>
                                        }
                                        <input className="form-control" type="text" placeholder="Enter your college name" name='collage' id={'collage' + index} value={edu.collage || ""} onChange={(eve) => handleChange(eve, index)} />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <div className="mb-xl-3 mb-lg-3 mb-md-3 mb-0">
                                        {index === 0 &&
                                            <label htmlFor="branch" className='fw-500 mb-1'>Branch or Course Name<span className='text-danger'> *</span></label>
                                        }
                                        <input className="form-control" type="text" placeholder="Enter name of your branch" name='branch' id={'branch' + index} value={edu.branch || ""} onChange={(eve) => handleChange(eve, index)} />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                    <div className="mb-xl-3 mb-lg-3 mb-md-3 mb-0">
                                        {index === 0 &&
                                            <label htmlFor="year" className='fw-500 mb-1'>Year Of Passing<span className='text-danger'> *</span></label>
                                        }
                                        <input className="form-control" type="date" placeholder="Year of assing" name='year' id={'year' + index} value={edu.year || ""} onChange={(eve) => handleChange(eve, index)} />
                                    </div>
                                </div>
                                <div className="col-xl-auto col-lg-auto col-md-auto col-6 d-flex align-items-center justify-content-center">
                                    <div className="">
                                        {index === 0 &&
                                            <label htmlFor="button" className='fw-500 mb-1'>Action</label>
                                        }
                                        <div className="mb-xl-3 mb-lg-3 mb-md-3 mb-0">
                                            {addEducation.length > 1 &&
                                                <button type='button' className='btn btn-sm btn-outline-success mx-1' onClick={() => eduAddMore('delete', index)}><i className="fa-solid fa-trash"></i></button>
                                            }

                                            {(index + 1) === (addEducation.length) &&
                                                <button type='button' className='btn btn-sm btn-outline-success mx-1' onClick={() => eduAddMore("add", index)}><i className="fa-solid fa-plus"></i></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-xl-none d-lg-none d-md-none d-block col-12">
                                    {(index + 1) !== (addEducation.length) &&
                                        <hr className='' />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="card-footer border-0 bg-white text-end">
                        <button type='submit' className='btn btn-success'><i className="fa-regular fa-circle-check me-2"></i>Update</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditEducationProfile