import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API_SETTINGS } from '../settings/settings'
import * as _underscore from 'underscore'
import no_image from '../assets/img/no_image_2.png'

const Course = () => {
    const { id } = useParams()
    const navigate = useNavigate();

    const [courseDetails, setCourseDetails] = useState({
        _id: "",
        type: "",
        title: "",
        rating: 0,
        ratings: [],
        reviews: [],
        description: "",
        image: "",
        lessons: 0,
        assignment: 0,
        duration: "",
        features: [],
        content: [],
        is_active: false,
    })

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        const getAllCourse = async () => {
            try {
                const res = await axios.post(API_SETTINGS.GET_COURSE, { id });
                const resData = res['data']
                let ratingArray = [];
                if (_underscore.isEmpty(resData)) {
                    navigate('/');
                    return
                }
                for (let i = 0; i < 5; i++) {
                    if (i <= resData['rating'] - 1) {
                        ratingArray.push('fa-solid fa-star text-warning');
                    } else {
                        ratingArray.push('fa-regular fa-star');
                    }
                }
                resData['ratings'] = ratingArray
                setCourseDetails(resData)

            } catch (error) {
                navigate('/');
            }
        }
        getAllCourse()
    }, [id, navigate])

    return (
        <section className="course-details">
            <div className="mt-4 py-5">
                <div className="container">
                    <div className="">
                        {courseDetails.title &&
                            <h3 className='course-details-title'>
                                <span className='fw-600'>{courseDetails.title}</span>
                                <span className='mx-2'>-</span>
                                <span className='course-title-sub'>({courseDetails.type})</span>
                            </h3>
                        }
                        <div className="row g-2">

                            {courseDetails.rating > 0 &&
                                <div className="col-auto">
                                    <div className="card course-details-rounded-card">
                                        <div className="card-body py-2">
                                            <span className="me-2">
                                                {courseDetails.ratings.map((rate, ind) => (
                                                    <i className={rate} key={ind}></i>
                                                ))}
                                            </span>
                                            <span className='fw-500'>{courseDetails.rating}.0</span>
                                            {courseDetails.reviews.length > 0 &&
                                                <span className='ms-2 course-reviews'>({courseDetails.reviews.length} Reviews)</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {courseDetails.lessons > 0 &&
                                <div className="col-auto fw-500">
                                    <div className="card course-details-rounded-card">
                                        <div className="card-body py-2">
                                            <span className="me-2"><i className="fa-solid fa-book"></i></span>
                                            <span className=''>{courseDetails.lessons} Lessons</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {courseDetails.assignment > 0 &&
                                <div className="col-auto fw-500">
                                    <div className="card course-details-rounded-card">
                                        <div className="card-body py-2">
                                            <span className="me-2"><i className="fa-solid fa-clipboard-list"></i></span>
                                            <span className=''>{courseDetails.assignment} Assignments</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {courseDetails.duration &&
                                <div className="col-auto fw-500">
                                    <div className="card course-details-rounded-card">
                                        <div className="card-body py-2">
                                            <span className="me-2"><i className="fa-regular fa-clock"></i></span>
                                            <span className=''>{courseDetails.duration}</span>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="mt-3 row g-2">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-12">

                            {courseDetails.image &&
                                <div className="course-details-image border">
                                    <img src={courseDetails.image} alt="" onError={onImageError} />
                                </div>
                            }

                            {courseDetails.short_desc &&
                                <>
                                    <div className="mt-5">
                                        <h4 className='fw-600'>About</h4>
                                        <p className="">{courseDetails.short_desc}</p>
                                    </div>
                                </>
                            }

                            {courseDetails.long_desc &&
                                <>
                                    <div className="mt-5">
                                        <h4 className='fw-600'>Description</h4>
                                        <p className="">{courseDetails.long_desc}</p>
                                    </div>
                                </>
                            }


                            {courseDetails.content.length > 0 &&
                                <div className="mt-5">
                                    <h4 className='fw-600'>Course Content</h4>
                                    <div className="accordion" id="accordionExample">
                                        {courseDetails.content.map((ele, index) => (
                                            <div className="accordion-item shadow-cu border-0 mb-2" key={index}>
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <Link className="accordion-button collapsed fw-500 border-0" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + index} aria-expanded="false" aria-controls="collapseTwo">
                                                        #{index} - {ele.title}
                                                    </Link>
                                                </h2>
                                                <div id={'collapse' + index.toString()} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            {ele.description.map((point, index) => (
                                                                <li key={index}>{point}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                            {courseDetails.features.length > 0 &&
                                <div className="card rounded-1rem border-0 shadow-cu">
                                    <div className="card-header bg-white py-3">
                                        <h5 className='fw-600 m-0'>This course includes:</h5>
                                    </div>
                                    <div className="card-body">
                                        {courseDetails.features.map((ele, ind) => (
                                            <div className="course-features" key={ind}>
                                                <div className="course-features-icon">
                                                    <i className={ele.icon + ""}></i>
                                                </div>
                                                <h6 className='course-features-title'>{ele.title}</h6>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Course