import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { API_SETTINGS } from '../settings/settings'
import no_image from '../assets/img/no_image_2.png'
import queryString from 'query-string';

const Courses = () => {
    const location = useLocation();
    const { domain } = queryString.parse(window.location.search)
    const [CoursesData, setCoursesData] = useState([])
    const [isLoader, setIsLoader] = useState(false)
    const domains = [
        { name: "All", alias: "All" },
        { name: "Mechanical", alias: "Mechanical" },
        { name: "ComputerScience", alias: "Computer Science" },
        { name: "Civil", alias: "Civil" },
        { name: "Electrical", alias: "Electrical" },
    ]
    const [domainStatus, setDomainStatus] = useState(domain)
    const getAllCoursesSection = async (selectedDomain) => {
        try {
            setDomainStatus(selectedDomain)
            setIsLoader(true)
            const res = await axios.get(`${API_SETTINGS.GET_COURSES_BY_DOMAIN}?domain=${selectedDomain}`);
            setIsLoader(false)
            const resData = res.data.sort((a, b) => {
                if (a.type < b.type) return -1
                return a.type > b.type ? 1 : 0
            })
            resData.forEach(element => {
                let ratingArray = [];
                for (let i = 0; i < 5; i++) {
                    if (i <= element['rating'] - 1) {
                        ratingArray.push('fa-solid fa-star text-warning');
                    } else {
                        ratingArray.push('fa-regular fa-star');
                    }
                }
                element['ratings'] = ratingArray
            });
            setCoursesData(resData)
        } catch (error) {
            setIsLoader(false)
        }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getAllCoursesSection(domain)
    }, [location, domain])

    return (
        <section className="domain-courses">
            <div className="height-55"></div>
            <div className="container">
                <div className="my-3">
                    <div className="row g-2">
                        {domains.map((domain) => (
                            <div className="col-xl-auto col-lg-auto col-md-auto col-auto" key={domain.name}>
                                <div className="domain-button">
                                    {/* <button type='button' className={`btn btn btn-light ${domain.name === domainStatus ? 'active' : ''}`} onClick={() => getAllCoursesSection(domain.name)}>{domain.alias}</button> */}
                                    <Link to={`?domain=${domain.name}`} className={`btn btn btn-light ${domain.name === domainStatus ? 'active' : ''}`}>{domain.alias}</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {isLoader &&
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-primary my-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }
                {!isLoader &&
                    <div className="row g-2">
                        {CoursesData.map((course, ind) => (
                            <div className="col-xl-4 col-lg-4 col-md-4 col-12" key={course._id}>
                                <div className="card courses-card border">
                                    <div className="card-body">
                                        <div className="row g-2">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="row g-2">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                        <Link to={'/course/' + course._id}>
                                                            <div className="courses-card-image border">
                                                                <img src={course.image} alt="" onError={onImageError} />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-xl-9 col-lg-9 col-md-12 col-12">
                                                        <div className="height-50 overflow-hidden">
                                                            <Link to={'/course/' + course._id}>
                                                                <h4 className='courses-card-text-title'>{course.title}</h4>
                                                            </Link>
                                                        </div>
                                                        <h6 className='mb-1'>
                                                            <span className="me-2">
                                                                {course?.ratings?.map((rate, ind) => (
                                                                    <i className={rate} key={ind}></i>
                                                                ))}
                                                            </span>
                                                            <span className='me-2'>|</span>
                                                            <span className='text-muted'><i className="fa-solid fa-hourglass-half me-2"></i>{course.duration}</span>
                                                        </h6>
                                                        <small className='fw-500 app-secondary-color'>{course.domain} {`${course.type} ${ind + 1}`}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="courses-card-text">
                                                    <div className='courses-card-text-description'>{course.short_desc}</div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <Link to={'/course/' + course._id}>
                                                    <button type="button" className="btn btn-primary app-primary-color-bg fw-500 border-0 w-100">Know more<i className="fa-solid fa-angles-right ms-2"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </section>
    )
}

export default Courses