import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getCourseDeatils } from '../../services/services'
import VideoJS from './video'
// import VideoJS from './VideoJS'
// import 'video.js/dist/video-js.css';

export const DashboardCourse = () => {
    const { id } = useParams()
    const [coursesDetails, setCoursesDetails] = useState()
    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controls: true,
        sources: [],
    })
    const playerRef = React.useRef(null);
    const courseDetails = async () => {
        try {
            const resData = await getCourseDeatils(id);
            setCoursesDetails(resData.data.data)
            const sources = [{
                src: resData.data.data.videos[1].video,
                type: 'video/webm',
            }]
            setVideoJsOptions({
                ...videoJsOptions,
                sources
            })
        } catch (error) {
        }
    }

    const onClickChangeVideo = (data) => {
        setVideoJsOptions({
            ...videoJsOptions,
            autoplay: true,
            sources: [{
                src: data.video,
                type: 'video/webm',
            }]
        })
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            VideoJS.log('player is waiting');
        });

        player.on('dispose', () => {
            VideoJS.log('player will dispose');
        });
    };

    useEffect(() => {
        courseDetails()
    }, [id])
    return (
        <section className='dashboard-course'>
            <div className="height-80"></div>
            <div className="container mb-5">
                <div className="row g-2">
                    <div className="col">
                        <Link to={"/dashboard"}>
                            <div className="profile-back">
                                <div className="profile-back-icon">
                                    <i className="fa-solid fa-arrow-left"></i>
                                </div>
                                <h6 className='profile-back-text'>Back to Dashboard</h6>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="row g-2">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                        <div className="card border-0">
                            <div className="card-body p-2">
                                <div className="video-player">
                                    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                                </div>
                                <div className="mt-3">
                                    <h5>{coursesDetails?.title}</h5>
                                    <p className='text-muted'>{coursesDetails?.short_desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                        <div className="card">
                            <div className="card-body p-2">
                                <div className="py-1 mb-3 text-center border-bottom">
                                    <h5 className='text-muted'>Course Content</h5>
                                </div>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        {coursesDetails?.videos?.map((video, index) => (
                                            <div className="border-bottom" key={video._id}>
                                                <h2 className="accordion-header" id="flush-headingOne" onClick={() => onClickChangeVideo(video)}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion${index}${video._id}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <span className='text-secondary me-2'>#{index + 1}</span>
                                                        <span className='fw-500'>{video.title}</span>
                                                    </button>
                                                </h2>
                                                <div id={`accordion${index}${video._id}`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="height-100"></div>
        </section>
    )
}
