import axios from 'axios';
import { useEffect, useState } from 'react'
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { API_SETTINGS } from '../settings/settings'
import heroSectionData from '../assets/static/herosections.json'
// import validator from 'validator'
import { toast } from 'react-hot-toast';
import Marquee from "react-fast-marquee";
const HeroSection = () => {
    const [HeroData, setHeroData] = useState(heroSectionData)
    // const [emailError, setEmailError] = useState(true)
    const [email, setEmail] = useState("")

    const easyButtons = [
        {
            name: "Courses",
            icon: "fa-solid fa-book-open",
            link: "#courses"
        },
        {
            name: "Career",
            icon: "fa-solid fa-route",
            link: "#careerpath"
        },
        {
            name: "Opportunities",
            icon: "fa-solid fa-handshake",
            link: "#companie"
        },
        {
            name: "Placements",
            icon: "fa-solid fa-briefcase",
            link: "#placements"
        },
    ]

    const validateEmail = (e) => {
        var email_value = e.target.value
        setEmail(email_value)
        // if (validator.isEmail(email_value)) {
        //     setEmailError(false)
        // } else {
        //     setEmailError(true)
        // }
    }

    const submitNewsletter = async (e) => {
        e.preventDefault();
        if (email && Object.keys(email).length > 5) {
            try {
                const res = await axios.post(API_SETTINGS.SUBSCRIBED_NEWSLETTER, { email }, { headers: { "Content-Type": "application/json", }, },);
                toast.success(res.data.message)
                setEmail('')
            } catch (e) {
                toast.error('Oops! Something went wrong!')
            }
        }
    }

    const getAllHeroSection = async () => {
        try {
            const ipAddress = await axios.get(API_SETTINGS.IP_IFY);
            const isMobile = window.navigator.userAgentData.mobile;
            const body = {
                address: ipAddress['data']['ip'],
                is_mobile: isMobile,
                is_web: isMobile ? false : true
            }
            const res = await axios.post(API_SETTINGS.GET_ALL_HERO_SECTION, body);
            setHeroData(res.data)
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllHeroSection()
    }, [])

    return (
        <section className="HeroSection">
            <div className="container ">
                <div className="row g-2">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div className="">
                            <h1 className='hero-title'>Design your Career in<span className="ms-2 text-danger">Designing</span></h1>
                            <h5 className='hero-description'>Learn about BIW and BIW components of a vehicle. basics of sheet metal.</h5>
                        </div>
                        <form onSubmit={submitNewsletter} className="mb-3">
                            <div className="hero-newsletter">
                                <i className="fa-solid fa-paper-plane me-2"></i>
                                <span>Subscribe to our newsletter and stay updated</span>
                            </div>
                            <div className="input-group hero-input-group">
                                <input type="email" className="form-control form-control-lg" placeholder="Email address" name='subscribed_input' id='subscribed_input' value={email} onChange={(e) => validateEmail(e)} autoComplete='off' />
                                <button type="submit" className="btn btn-primary" name='subscribed_button' id="subscribed_button">Subscribe</button>
                            </div>
                        </form>
                        <div className="row g-2 hero-easy-buttons">
                            {easyButtons?.map((ele) => (
                                <div className="col-xl-auto col-lg-auto col-md-3 col" key={ele.name}>
                                    <a href={ele.link}>
                                        <div className="hero-easy-buttons-body">
                                            <i className={ele.icon + ' me-2'}></i>
                                            <span>{ele.name}</span>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="py-3 mt-3 border-top border-bottom">
                            <Marquee pauseOnHover speed={50}>
                                <div className=''>
                                    <span className='mx-2 fw-500'>* Online Batches are Starting Soon</span>
                                    <span className='mx-2 fw-500'>* 100 % Placement Assistance</span>
                                    <span className='mx-2 fw-500'>* Lifetime career guidance</span>
                                    <span className='mx-2 fw-500'>* NX CAD Courses</span>
                                </div>
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroSection