import React from 'react'
import * as moment from 'moment'
import no_data from '../assets/img/no_data_amico.png'

const ViewProfile = ({ data }) => {
    const userDetails = data
    return (
        <>
            <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                <div className='card border-0 h-100'>
                    <div className="card-header px-4 pt-4 border-0 bg-white">
                        <h5 className='fw-600 mb-0'>Personal Information</h5>
                    </div>
                    <div className="card-body">
                        <div className="row g-3">

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="profile-personal">
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title">{userDetails.email}</h6>
                                                <small className="profile-personal-text-label">Email Address</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="profile-personal">
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-mobile-screen-button"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title">{userDetails.mobile ? '+91 ' + userDetails.mobile : '--- ----- -----'}</h6>
                                                <small className="profile-personal-text-label">Mobile Number</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="profile-personal">
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-cake-candles"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title">
                                                    {moment(new Date(userDetails.date_of_birth)).format("Do MMMM YYYY")}
                                                </h6>
                                                <small className="profile-personal-text-label">{moment().diff(new Date(userDetails.date_of_birth), 'years')} Years old</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="profile-personal">
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-venus-mars"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title">{userDetails.gender ? userDetails.gender : '-----'}</h6>
                                                <small className="profile-personal-text-label">Gender</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="profile-personal">
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title m-0">
                                                    {userDetails.address.address ? userDetails.address.address : '----------'} {userDetails.address.city ? userDetails.address.city : '----------'}
                                                </h6>
                                                <small className="profile-personal-text-label">
                                                    {userDetails.address.country ? userDetails.address.country : 'Address'} {userDetails.address.state ? userDetails.address.state : ''} {userDetails.address.pincode ? ' - ' + userDetails.address.pincode : ''}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                <div className='card border-0 h-100'>
                    <div className="card-header px-4 pt-4 border-0 bg-white">
                        <h5 className='fw-600 mb-0'>Education Information</h5>
                    </div>
                    {userDetails.education.length === 0 &&
                        <div className="card-body">
                            <div className="no-data">
                                <img src={no_data} alt="" />
                            </div>
                        </div>
                    }
                    {userDetails.education.length > 0 &&
                        <div className="card-body">
                            {userDetails.education.map((edu, index) => (
                                <div className="profile-personal" key={index}>
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className="fa-solid fa-graduation-cap"></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title mb-0">{edu.collage}</h6>
                                                <small className="profile-personal-text-label text-info">{edu.branch}</small>
                                                <div className="">
                                                    <small className="profile-personal-text-label">{moment(new Date(edu.year)).format("MMMM YYYY")}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>

            <div className="col-xl-auto col-lg-auto col-md-12 col-12">
                <div className='card border-0 h-100'>
                    <div className="card-header px-4 pt-4 border-0 bg-white">
                        <h5 className='fw-600 mb-0'>Resume</h5>
                    </div>
                    {userDetails.resume.length === 0 &&
                        <div className="card-body">
                            <div className="no-data">
                                <img src={no_data} alt="" />
                            </div>
                        </div>
                    }
                    {userDetails.resume.length > 0 &&
                        <div className="card-body">
                            {userDetails.resume.sort((a, b) => b.file_name.toLowerCase() > a.file_name.toLowerCase() ? 1 : -1).map((resume, ind) => (
                                <div className="profile-personal" key={ind}>
                                    <div className="row g-2">
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <div className="profile-personal-icon">
                                                <i className={resume.file_ext === 'pdf' ? 'fa-solid fa-file-pdf text-danger' : 'fa-regular fa-file-word text-primary'}></i>
                                            </div>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <h6 className="profile-personal-text-title mb-0">{resume.file_name}.{resume.file_ext}</h6>
                                            </div>
                                        </div>
                                        <div className="col-auto d-flex align-items-center">
                                            <div className="profile-personal-text">
                                                <a className='btn btn-secondary btn-sm rounded-10px fw-500' href={resume.file_data} download={resume.file_name + '.' + resume.file_ext}><i className="fa-solid fa-download"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ViewProfile