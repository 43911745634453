import axios from 'axios';
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import headerLogo from '../assets/img/logo.png'
import { API_SETTINGS } from '../settings/settings';
import toast from 'react-hot-toast';
import jwt_decode from "jwt-decode";
import loginImage from '../assets/img/5243319.png'

const SignIn = () => {
    const location = useLocation().search;
    const navigate = useNavigate()
    const [isMobileNumber, setIsMobileNumber] = useState(false)
    const [mobileLoader, setMobileLoader] = useState(false)
    const [otpLoader, setOtpLoader] = useState(false)
    const mobile = new URLSearchParams(location).get("mobile");
    const [signInForm, setSignInForm] = useState({
        mobile: mobile ? mobile : "",
        OTP: "",
    })

    const MobileHandler = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSignInForm({
                mobile: event.target.value,
                OTP: ""
            });
        }
    }

    const OtpHandler = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSignInForm({
                ...signInForm,
                OTP: event.target.value,
            });
        }
    }

    const submitSignIn = async () => {
        try {
            const data = { mobile: signInForm.mobile }
            const config = {
                method: 'POST',
                url: API_SETTINGS.SIGN_IN,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            setMobileLoader(true)
            const res = await axios(config)
            setMobileLoader(false)
            if (res && res?.status === 200) {
                setIsMobileNumber(true)
                setSignInForm({
                    ...signInForm,
                    OTP: "",
                });
                toast.success(res.data.message)
            } else {
                setIsMobileNumber(false)
                toast.error(res.data.message)
            }
        } catch (error) {
            setMobileLoader(false)
            setIsMobileNumber(false)
            toast.error(error.response.data.message)
        }
    }

    const submitVerifyOtp = async () => {
        try {
            var data = {
                mobile: signInForm.mobile,
                OTP: signInForm.OTP
            }
            var config = {
                method: 'POST',
                url: API_SETTINGS.VERIFY_OTP,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            setOtpLoader(true)
            const resData = await axios(config)
            if (resData && resData?.status === 200) {
                navigate(resData.data.redirect_url)
                toast.success(resData.data.message)
                setSignInForm({
                    mobile: "",
                    OTP: ""
                });
                sessionStorage.setItem('accessToken', resData?.data?.accessToken)
                sessionStorage.setItem('refreshToken', resData?.data?.refreshToken)
                const { first_name } = jwt_decode(resData?.data?.accessToken);
                sessionStorage.setItem('user', first_name)
            }
            setOtpLoader(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setOtpLoader(false)
        }
    }

    const formSubmission = async (e) => {
        e.preventDefault();
        if (!isMobileNumber) {
            await submitSignIn()
            return
        } else {
            await submitVerifyOtp()
            return
        }
    }

    return (
        <section className="signin">
            <div className="container">
                <div className="card signin-card">
                    <div className="card-body p-4">
                        <div className="row g-0">
                            <div className="col d-none d-md-flex justify-content-center align-items-center">
                                <div className="login-image">
                                    <img src={loginImage} alt=""/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                <div className="">
                                    <div className="mb-3 text-end">
                                        <Link to={"/"}>
                                            <h6 className='text-secondary cursor-pointer'><i className="fa-solid fa-caret-left me-1"></i>Back to Home</h6>
                                        </Link>
                                    </div>
                                    <div className="my-4 text-center d-flex justify-content-center align-items-center">
                                        <div className="signin-card-logo">
                                            <img src={headerLogo} alt="" />
                                        </div>
                                        <h2 className='signin-card-title'>Signo Venture</h2>
                                    </div>
                                    {!isMobileNumber &&
                                        <div className="mb-4 text-center">
                                            <p>"Education is the most powerful weapon which you can use to change the world. to instil in a young engineer the confidence they need to succeed at the highest level possible in the technical field and in the relevant domain."</p>
                                        </div>
                                    }
                                    <div className="signin-card-form">
                                        <form action="" method="post" onSubmit={formSubmission}>
                                            <h5 className='text-center'><i className="fa-solid fa-minus me-1"></i>Sign In<i className="fa-solid fa-minus ms-1"></i></h5>
                                            <div className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between p-1">
                                                    <label htmlFor="mobile" className="app-secondary-color fw-500">Mobile No :</label>
                                                    {isMobileNumber &&
                                                        <small className="text-end text-secondary cursor-pointer fw-500" onClick={() => setIsMobileNumber(false)}><i className="fa-solid fa-caret-left me-1"></i>Edit</small>
                                                    }
                                                </div>
                                                <input type="tel" className={isMobileNumber ? 'form-control border-primary bg-white fw-500 cursor-no-drop' : 'form-control bg-white fw-500'} name='mobile' id="mobile" placeholder="Enter mobile number" autoComplete='off' maxLength={10} value={signInForm.mobile} onChange={MobileHandler} disabled={isMobileNumber} />
                                            </div>
                                            {isMobileNumber &&
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-center justify-content-between p-1">
                                                        <label htmlFor="otpNumber" className="app-secondary-color fw-500">Enter OTP :</label>
                                                        <small className="text-end text-danger cursor-pointer fw-500" onClick={() => submitSignIn()}><i className="fa-solid fa-arrow-rotate-right me-1"></i>Resend OTP</small>
                                                    </div>
                                                    <input type="tel" className="form-control fw-500" name='otpNumber' id="otpNumber" placeholder="Enter your OTP" value={signInForm.OTP} maxLength="6" onChange={OtpHandler} />
                                                </div>
                                            }
                                            <div className="mb-3 text-end">
                                                {!isMobileNumber && !mobileLoader &&
                                                    <button type="submit" className="btn btn-success fw-500 w-100"><i className="fa-solid fa-paper-plane me-2"></i>Send OTP</button>
                                                }
                                                {!isMobileNumber && mobileLoader &&
                                                    <button type="submit" className="btn btn-success fw-500 w-100" disabled>
                                                        <div className="spinner-border text-light spinner-border-sm me-2" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                        Sending OTP
                                                    </button>
                                                }
                                                {isMobileNumber && !otpLoader &&
                                                    <button type="submit" className='btn btn-success fw-500 w-100'><i className="fa-solid fa-right-to-bracket me-2"></i>Sign In</button>
                                                }
                                                {isMobileNumber && otpLoader &&
                                                    <button type="submit" className="btn btn-success fw-500 w-100" disabled>
                                                        <div className="spinner-border text-light spinner-border-sm me-2" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                        Sending OTP
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                        <div className="text-center">
                                            <h6>Don't have an account? <Link to={'/sign-up'} className='bg-warning px-2 py-1 text-black rounded'>Sign Up</Link></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default SignIn