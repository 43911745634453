import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_SETTINGS } from '../settings/settings';
import CountUp from 'react-countup';

const Countups = () => {
    const [CountupsData, setCountupsData] = useState([])
    const getAllCountups = async () => {
        try {
            const res = await axios.get(API_SETTINGS.GET_COUNTUPS);
            setCountupsData(res.data)
        } catch (error) { }
    }

    useEffect(() => {
        getAllCountups()
    }, [])
    return (
        <section className="countups">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className=''>Our<span className='text-danger mx-1'>Numbers Says it all</span></h2>
                    <p className=''>They are still counting...</p>
                </div>
                <div className="row g-2">
                    {CountupsData.map((count, index) => (
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6" key={index}>
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="countups-icon">
                                        <i className={count.icon}></i>
                                    </div>
                                    <div className="text-center">
                                        <h4 className='app-secondary-color'>
                                            <CountUp start={count.min} end={count.max} duration={15} />
                                        </h4>
                                        <h5 className='app-primary-color'>{count.title}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Countups