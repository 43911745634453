import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { getUserProfile } from '../../services/services'
import headerLogo from '../../assets/img/logo.png'
import headerUser from '../../assets/img/Avatar_01.png'

const BaseHeader = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const accessToken = sessionStorage.getItem('accessToken')
  const [isLogin, setIsLogin] = useState(false)
  const [isBottomNavBar, setIsBottomNavBar] = useState(false)
  const first_name = sessionStorage.getItem('user')
  const [userDetails, setUserDetails] = useState({
    first_name: first_name ? first_name : "",
    last_name: "",
    display_name: "",
    email: "",
    image: "",
  })

  const signOut = () => {
    sessionStorage.removeItem('accessToken')
    if (location.pathname !== '/') {
      navigate('/')
    }
  }

  const getUserDetails = async () => {
    try {
      const scopes = [
        "first_name",
        "image",
        "mobile",
        "last_login",
      ]
      const result = await getUserProfile(scopes)
      setUserDetails(result.data)
    } catch (error) {
      signOut()
    }
  }

  useEffect(() => {
    if (accessToken) {
      getUserDetails()
    }
    setIsLogin(accessToken ? true : false)
    // eslint-disable-next-line
  }, [accessToken])

  useEffect(() => {
    const currentUrl = location.pathname.split('/')[1]
    const bgIncludes = ['profile', 'dashboard']
    const BottomNavBar = ['', '/', 'profile', 'course', 'dashboard']
    // const Marquee = ['', '/']
    if (bgIncludes.includes(currentUrl)) {
      document.body.classList.add('body-bg');
    } else {
      document.body.classList.remove('body-bg');
    }
    setIsBottomNavBar(BottomNavBar.includes(currentUrl))
  }, [location])

  return (
    <>
      <header id="header" className={`web-header fixed-top web-header-bg`}>
        <div className="container d-flex align-items-center justify-content-between">

          <Link to="/" className="web-header-logo d-flex align-items-center">
            <div className="web-header-logo-image">
              <img src={headerLogo} alt="" />
            </div>
            <h1 className="web-header-logo-title">Signo Venture</h1>
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li><a className={isLogin ? 'nav-link active' : 'nav-link'} href="tel:+917200094576"><i className='fa-solid fa-headset me-2'></i>Support</a></li>
              {!isLogin &&
                <>
                  <li>
                    <Link className="nav-link scrollto" to='/sign-in'>
                      <i className="fa-solid fa-right-to-bracket"></i>
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link active" to='/sign-up'>
                      <i className="fa-regular fa-file-lines"></i>
                      Sign Up
                    </Link>
                  </li>
                </>
              }
              {isLogin &&
                <li className="dropdown">
                  <Link className='web-header-user'>
                    <div className="web-header-user-image">
                      <img src={userDetails.image ? userDetails.image : headerUser} alt="" />
                    </div>
                    <span className='web-header-user-title'>{userDetails.first_name ? userDetails.first_name : "Hi! Welcome"}</span>
                    <i className="fa-solid fa-chevron-down"></i>
                  </Link>
                  <ul>
                    {userDetails?.is_user &&
                      <li><Link to="/dashboard"><i className="fa-solid fa-server"></i>Dashboard</Link></li>
                    }
                    {userDetails?.is_manager &&
                      <li><Link to="manager/dashboard"><i className="fa-solid fa-server"></i>Dashboard</Link></li>
                    }
                    <li><Link to="/dashboard"><i className="fa-solid fa-user-shield"></i>Dashboard</Link></li>
                    <li><Link to="" onClick={signOut}><i className="fa-solid fa-right-from-bracket"></i>Sign Out</Link></li>
                  </ul>
                </li>
              }
            </ul>
          </nav>

          <div className="mobile-navbar d-none">
            <a className="mobile-navbar-support active" href="tel:+917200094576">
              <i className='fa-solid fa-headset me-2'></i>
              Support
            </a>
          </div>

        </div>
      </header>

      {isBottomNavBar &&
        <div className="bottom-navbar">
          <nav className="">
            <ul className="nav nav-pills">
              {isLogin && userDetails?.is_user &&
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="fa-solid fa-server"></i>
                    <h6 className='nav-link-title'>Dashboard</h6>
                  </Link>
                </li>
              }
              {isLogin && userDetails?.is_manager &&
                <li className="nav-item">
                  <Link className="nav-link" to="manager/dashboard">
                    <i className="fa-solid fa-server"></i>
                    <h6 className='nav-link-title'>Dashboard</h6>
                  </Link>
                </li>
              }
              <li className="nav-item">
                <HashLink smooth className="nav-link" to="/#courses">
                  <i className="fa-solid fa-book-open"></i>
                  <h6 className='nav-link-title'>Courses</h6>
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink smooth className="nav-link" to="/#freesection">
                  <i className="fa-solid fa-file-signature"></i>
                  <h6 className='nav-link-title'>Book Demo</h6>
                </HashLink>
              </li>
              {isLogin &&
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <div className="nav-link-image border">
                      <img src={userDetails.image ? userDetails.image : headerUser} alt="" />
                    </div>
                    <h6 className='nav-link-title'>Profile</h6>
                  </Link>
                </li>
              }
              {!isLogin &&
                <li className="nav-item">
                  <Link className="nav-link active" to="/sign-up">
                    <i className="fa-regular fa-file-lines"></i>
                    <h6 className='nav-link-title'>Sign Up</h6>
                  </Link>
                </li>
              }
              {!isLogin &&
                <li className="nav-item">
                  <HashLink smooth className="nav-link" to="/sign-in">
                    <i className="fa-solid fa-right-to-bracket"></i>
                    <h6 className='nav-link-title'>Sign In</h6>
                  </HashLink>
                </li>
              }
            </ul>
          </nav>
        </div>
      }
    </>

  )
}

export default BaseHeader