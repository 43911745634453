import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_SETTINGS } from '../settings/settings';
import no_image from '../assets/img/no_image_2.png'

const Companies = () => {
    const [companie, setCompanie] = useState([])

    const getCompanies = async () => {
        try {
            const res = await axios.get(API_SETTINGS.GET_ALL_COMPANIES);
            setCompanie(res.data)
        } catch (error) { }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getCompanies()
    }, [])
    return (
        <section className='companie' id='companie'>
            <div className="header-hight"></div>
            <div className="container">
                <div className="section-title">
                    <h2 className=''>Opportunities at<span className='text-danger mx-2'>Companies</span></h2>
                    <p className=''><b>Skilled Designers</b> Are Highly in Demand by Companies</p>
                </div>
                <div className="companie-content">
                    <div className="row g-3">
                        {companie.map((companie) => (
                            <div className="col-xl-4 col-lg-4 col-md-4 col-6" key={companie._id}>
                                <div className="companie-image">
                                    <picture>
                                        <img src={companie.image} alt="" onError={onImageError} />
                                    </picture>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Companies