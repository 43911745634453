import React from 'react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { VerifyEmailAddress } from '../services/services';
import { useState } from 'react';
const VerifyEmail = () => {
    const [isVerifyEmail, setVerifyEmail] = useState({
        status: false,
        statusCode: 0,
        message: "Please wait verifying...."
    })
    const location = useLocation()

    const VerifyEmailId = async (location) => {
        try {
            const result = await VerifyEmailAddress(location)
            setVerifyEmail({
                ...result?.data,
                status: true
            })
        } catch (error) {}
    }

    useEffect(() => {
        VerifyEmailId(location.search)
    }, [location.search])
    return (
        <section className="">
            <div className="height-60"></div>
            <div className="container p-3">
                <div className="text-center app-background-color-bg width-fit-content mx-auto px-3 py-1 rounded-10px mb-4">
                    <h5 className='m-0'>Email Address Verification</h5>
                </div>
                <div className="text-center text-success mb-3">
                    {isVerifyEmail.status && isVerifyEmail.statusCode === 200 &&
                        <i className="fa-solid fa-circle-check fa-2x"></i>
                    }
                    {isVerifyEmail.status && isVerifyEmail.statusCode !== 200 &&
                        <i className="fa-solid fa-circle-xmark fa-2x"></i>
                    }
                    {!isVerifyEmail.status &&
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
                <div className="text-center">
                    <h5>{isVerifyEmail.message}</h5>
                </div>
            </div>
        </section>
    )
}

export default VerifyEmail