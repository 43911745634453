import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_SETTINGS } from '../settings/settings'
import { useNavigate } from 'react-router-dom'
import * as moment from 'moment'

const EditResumeProfile = (compData) => {
    const data = compData.data
    const navigate = useNavigate()
    let uploadResume = {}

    const [resumeFiles, setResumeFiles] = useState([])

    const resumeAddMore = (type, index) => {
        if (type === 'delete') {
            let resumeArray = [...resumeFiles]
            resumeArray.splice(index, 1);
            setResumeFiles(resumeArray)
        }
    }

    const convertBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleChange = async (event) => {
        try {
            const file = event.target.files[0]
            const file_ext = file.name.split('.').pop()
            const allowFiles = ['pdf', 'doc', 'docx']

            if (!allowFiles.includes(file_ext)) {
                toast.error(`Oops! Invalid file format!`)
                return
            }

            if (file.size > 1048576) {
                toast.error(`Oops! Uploaded file size should be less than 1mb!`)
                return
            }
            else {
                let fileNum = data.resume.length + 1
                fileNum = fileNum < 10 ? "Resume_00" + fileNum : "Resume_0" + fileNum
                const base64 = await convertBase64(file)
                uploadResume = {
                    file_name: fileNum,
                    file_data: base64,
                    file_ext: file.name.split('.').pop(),
                    time_stamp: new Date(),
                    file_details: {
                        name: file.name,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        type: file.type,
                        size: file.size,
                    }
                }
            }
        } catch (error) {
            toast.error(`Oops! No files has been chosen!`)
        }
    }

    const submitEditForm = async (e) => {
        e.preventDefault();
        const accessToken = sessionStorage.getItem('accessToken')
        let submitData = []
        resumeFiles.forEach(e => {
            if (e.file_data) {
                submitData.push(e)
            }
        });
        if (uploadResume.file_data) {
            submitData.push(uploadResume)
            uploadResume = {}
        }
        if (accessToken) {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
            const body = {
                email: data.email,
                update_values: {
                    resume: submitData
                }
            }
            const res = await axios.patch(API_SETTINGS.CURRENT_USER, body, config);
            toast.success(res.data.message)
            compData.status('view')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        setResumeFiles(data.resume)
    }, [data.resume])

    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="card border-0">
                <div className="card-header bg-white border-0 py-3">
                    <h5 className='m-0'>Edit Resume's</h5>
                </div>
                <div className="card-body">
                    <div className="resume-file-upload">
                        <div className="">
                            <label htmlFor="formFile" className="form-label">Upload New Resume</label>
                            <input className="form-control form-control-sm" type="file" id="formFile" onChange={handleChange} accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </div>
                        <small className='text-secondary'>File size should be less than 1 mb</small>
                    </div>
                    <hr />
                    {resumeFiles.length > 0 &&
                        <div className="row g-2">
                            <div className="col-xl-auto col-lg-auto col-md-auto col-12">
                                <label className='text-primary fw-500 border-bottom mb-2'>Already Uploaded Resume</label>
                                <div className="table-responsive resumes-table">
                                    <table className="table text-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="text-center">File</th>
                                                <th className="">Resume</th>
                                                <th className="">File Name</th>
                                                <th className="text-center">Uploaded</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resumeFiles.sort((a, b) => b.file_name.toLowerCase() > a.file_name.toLowerCase() ? 1 : -1).map((resume, index) => (
                                                <tr key={index}>
                                                    <td className='text-center'><i className={resume.file_ext === 'pdf' ? 'fa-solid fa-file-pdf text-danger' : 'fa-regular fa-file-word text-primary'}></i></td>
                                                    <td>{resume.file_name}</td>
                                                    <td>{resume.file_details.name}</td>
                                                    <td className='text-center'>{moment(new Date(resume.time_stamp)).format("DD-MM-YYYY hh:mm a")}</td>
                                                    <td className='text-center'>
                                                        <button type='button' className='btn btn-sm btn-outline-danger' onClick={() => resumeAddMore('delete', index)}><i className="fa-solid fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="card-footer border-0 bg-white text-end">
                    <button type='button' className='btn btn-success' onClick={submitEditForm}><i className="fa-regular fa-circle-check me-2"></i>Update</button>
                </div>
            </div>
        </div>
    )
}

export default EditResumeProfile