import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import axios from 'axios';
import { API_SETTINGS } from '../settings/settings';
import no_image from '../assets/img/no_image_2.png'

const Testimonials = () => {
    const [testimonialData, setTestimonialData] = useState([])

    const getTestimonials = async () => {
        try {
            const res = await axios.get(API_SETTINGS.GET_ALL_TESTIMONIALS);
            let resData = res.data.map((ele) => {
                let ratingArray = [];
                for (let i = 0; i < 5; i++) {
                    if (i <= ele.rating - 1) {
                        ratingArray.push('fa-solid fa-star text-warning');
                    } else {
                        ratingArray.push('fa-regular fa-star');
                    }
                }
                return {
                    ...ele,
                    rating: ratingArray,
                };
            })
            setTestimonialData(resData)
        } catch (error) { }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getTestimonials()
    }, [])

    return (
        <section className='testimonials'>
            <div className="container">

                <div className="section-title">
                    <h2 className=''>Our<span className='text-danger mx-1'>Testimonials</span></h2>
                    <p className=''><b>Signo Venture</b> has received honest feedback from our learners around the globe.</p>
                </div>

                <div className='testimonials-content'>
                    <Swiper
                        spaceBetween={5}
                        autoplay={{ delay: 3500, disableOnInteraction: false, }}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        breakpoints={{ 640: { slidesPerView: 1 }, 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3 }, }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper">

                        {testimonialData.map((test, index) => (
                            <SwiperSlide key={index} className='p-2'>
                                <div className="card testimonials-card">
                                    <div className="card-body">
                                        <div className="testimonial-message">
                                            <p className='m-0 fw-500'><i className="fa-solid fa-quote-left me-1"></i>{test.testimonial}<i className="fa-solid fa-quote-right ms-1"></i></p>
                                        </div>
                                    </div>
                                    <div className="card-footer testimonial-author">
                                        <div className="row g-2">
                                            <div className="col">
                                                <div className="testimonial-author-text">
                                                    <h6 className=''><i className="fa-solid fa-user-tie me-2"></i>{test.name}</h6>
                                                    <div className="">
                                                        {test.rating.map((rate, ind) => (
                                                            <i className={rate} key={ind}></i>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="testimonial-author-image">
                                                    <img src={test.image} alt="" onError={onImageError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Testimonials