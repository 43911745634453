import axios from 'axios'
import validator from 'validator'
import toast from 'react-hot-toast'
import React, { useState } from 'react'
import { API_SETTINGS } from '../settings/settings'
import freeSectionImage from '../assets/img/free_section.png'
import no_image from '../assets/img/no_image_2.png'

const FreeSection = () => {
    const [isMessage, setIsMessage] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")

    function handleChange(event) {
        switch (event.target.name) {
            case 'demo_name':
                setName(event.target.value)
                break;
            case 'demo_email':
                validateEmail(event)
                break;
            case 'demo_mobile':
                isMobile(event)
                break;
            default:
                break;
        }
    }

    const isMobile = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setMobile(event.target.value)
        }
    }

    const validateEmail = (e) => {
        var email_value = e.target.value
        setEmail(email_value)
        if (validator.isEmail(email_value)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }

    const createFreeDemo = async (e) => {
        e.preventDefault();
        if (name && Object.keys(name).length > 3 && email && Object.keys(email).length > 5 && mobile && Object.keys(mobile).length === 10) {
            try {
                const body = {
                    name: name,
                    email: email,
                    mobile: mobile
                }
                const res = await axios.post(API_SETTINGS.CREATE_FREE_SECTION, body, { headers: { "Content-Type": "application/json", }, },);
                toast.success(res.data.message)
                if (res.status === 201) {
                    setIsMessage(true)
                    setName("")
                    setEmail("")
                    setMobile("")
                }
            } catch (e) {
                toast.error('Oops! Something went wrong!')
            }
        } else {
            toast.error('Oops! Check the details you have entered!', { duration: 2000 })
        }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    return (
        <section className='freedemo' id='freesection'>
            <div className="card freedemo-card">
                <div className="card-body py-4">
                    <div className="row g-3 mb-4">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-12 d-flex justify-content-center align-items-center">
                            <div className="freedemo-card-title">
                                <h3 className=''>Free Demo <span className='text-danger'>Session</span></h3>
                                <p className=''>Just answer a few simple details</p>
                                <p className=''>so we can contact you as soon as possible</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                            <div className="freedemo-card-image border">
                                <img src={freeSectionImage} alt="" onError={onImageError} />
                            </div>
                        </div>
                    </div>
                    <div className="px-3">
                        {!isMessage &&
                            <form action="" method="post" onSubmit={createFreeDemo}>
                                <div className="row g-3">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div className="form-group">
                                            <label htmlFor="demo_email" className='fw-500 mb-1'>Full Name<span className='text-danger'>*</span></label>
                                            <input className="form-control form-control" type="text" placeholder="full name" name='demo_name' id='demo_name' value={name} onChange={handleChange} autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div className="form-group">
                                            <label htmlFor="demo_email" className='fw-500 mb-1'>Mobile Number <span className='text-danger'>*</span></label>
                                            <input className="form-control form-control" type="text" inputMode='numeric' pattern="[0-9]*" maxLength="10" placeholder="xxxxx xxxxx" name='demo_mobile' id='demo_mobile' value={mobile} onChange={handleChange} autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="demo_email" className='fw-500 mb-1'>Email Address <span className='text-danger'>*</span></label>
                                            <input className="form-control form-control" type="email" placeholder="example@gmail.com" name='demo_email' id='demo_email' value={email} onChange={handleChange} autoComplete='off' />
                                            {emailError &&
                                                <small className='text-danger fw-500'>Invalid email address</small>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group text-end">
                                            <button type='submit' className='btn btn-success'><i className="fa-brands fa-get-pocket me-2"></i>Request Now</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        }
                        {isMessage &&
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <i className="fa-solid fa-circle-check text-success fa-3x"></i>
                                <div className="mt-3 text-center">
                                    <h5 className='web-primary-colour'>We received your request will be in touch shortly</h5>
                                    <h6 className='fw-500 text-danger'>Thanks for Choosing us</h6>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FreeSection