import React from 'react'
import BaseFooter from '../components/BaseFooter'
import CareerPath from '../components/CareerPath'
import Companies from '../components/Companies'
import Countups from '../components/Countups'
import Courses from '../components/Courses'
import HeroSection from '../components/HeroSection'
// import Newsletters from '../components/Newsletters'
import Placements from '../components/Placements'
import Testimonials from '../components/Testimonials'

const HomePage = () => {
    return (
        <>
            <HeroSection></HeroSection>
            <Courses></Courses>
            <CareerPath></CareerPath>
            <Companies></Companies>
            <Placements></Placements>
            {/* <Newsletters></Newsletters> */}
            <Countups></Countups>
            <Testimonials></Testimonials>
            <BaseFooter></BaseFooter>
        </>
    )
}

export default HomePage