import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_SETTINGS } from '../settings/settings'
import { useNavigate } from 'react-router-dom'

const EditPersonalProfile = (compData) => {
    const data = compData.data
    const navigate = useNavigate()
    const [editProfile, setEditProfile] = useState({
        mobile: "",
        date_of_birth: "",
        gender: "",
        address: {
            address: "",
            country: "",
            state: "",
            city: "",
            pincode: ""
        },
    })

    const handleChange = (evt) => {
        setEditProfile({
            ...editProfile,
            [evt.target.name]: evt.target.value
        });
    }

    const handleChangeAddress = (evt) => {
        setEditProfile({
            ...editProfile,
            address: {
                ...editProfile.address,
                [evt.target.name]: evt.target.value
            }
        });
    }

    const isMobile = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            handleChange(event)
        }
    }

    const submitEditForm = async (e) => {
        e.preventDefault();
        const accessToken = sessionStorage.getItem('accessToken')
        if (accessToken) {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
            let body = {
                update_values: editProfile
            }
            const res = await axios.patch(API_SETTINGS.CURRENT_USER, body, config);
            toast.success(res.data.message)
            compData.status('view')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        setEditProfile({
            mobile: data.mobile,
            date_of_birth: data.date_of_birth,
            gender: data.gender,
            address: {
                address: data.address.address,
                country: data.address.country,
                state: data.address.state,
                city: data.address.city,
                pincode: data.address.pincode,
            },
        })
    }, [data])

    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="card border-0">
                <div className="card-header bg-white border-0 py-3">
                    <h5 className='m-0'>Edit Personal Info</h5>
                </div>
                <div className="card-body">
                    <form className='' onSubmit={submitEditForm}>
                        <div className="row g-2">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="mobile" className='fw-500 mb-1'>Mobile Number<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" inputMode='numeric' pattern="[0-9]*" maxLength="10" placeholder="xxxxx xxxxx" name='mobile' id='mobile' value={editProfile.mobile} onChange={isMobile} required />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="date_of_birth" className='fw-500 mb-1'>Date Of Birth<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="date" name='date_of_birth' id='date_of_birth' value={moment(new Date(editProfile.date_of_birth)).format("yyyy-MM-DD")} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div className="">
                                    <label htmlFor="gender" className='fw-500 mb-1'>Gender<span className='text-danger'> *</span></label>
                                    <div className="mx-2">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="gender" id="gender_male" value='Male' checked={editProfile.gender === 'Male'} onChange={handleChange} required />
                                            <label className="form-check-label" htmlFor="gender_male">Male</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="gender" id="gender_female" value='Female' checked={editProfile.gender === 'Female'} onChange={handleChange} required />
                                            <label className="form-check-label" htmlFor="gender_female">Female</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="address" className='fw-500 mb-1'>Address<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" name='address' id='address' value={editProfile.address.address} onChange={handleChangeAddress} placeholder="Address" required />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="country" className='fw-500 mb-1'>Country<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" name='country' id='country' value={editProfile.address.country} onChange={handleChangeAddress} placeholder="Country" required />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="state" className='fw-500 mb-1'>State<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" name='state' id='state' value={editProfile.address.state} onChange={handleChangeAddress} placeholder="State" required />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="city" className='fw-500 mb-1'>City<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" name='city' id='city' value={editProfile.address.city} onChange={handleChangeAddress} placeholder="City" required />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="pincode" className='fw-500 mb-1'>Pincode<span className='text-danger'> *</span></label>
                                    <input className="form-control" type="text" name='pincode' id='pincode' value={editProfile.address.pincode} onChange={handleChangeAddress} placeholder="Pincode" required />
                                </div>
                            </div>
                            <div className="col-12 text-end">
                                <button type='submit' className='btn btn-success'><i className="fa-regular fa-circle-check me-2"></i>Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditPersonalProfile