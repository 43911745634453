import axios from 'axios'
import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_SETTINGS } from '../settings/settings'
import no_image from '../assets/img/no_image_2.png'
// import AllCourses from '../assets/static/getAllCourses.json'

const Courses = () => {
    // const staticData = AllCourses.sort((a, b) => {
    //     if (a.type < b.type) return -1
    //     return a.type > b.type ? 1 : 0
    // })
    const [CoursesData, setCoursesData] = useState([])
    const domains = [
        { name: "Mechanical", alias: "Mechanical" },
        { name: "ComputerScience", alias: "Computer Science" },
        { name: "Civil", alias: "Civil" },
        { name: "Electrical", alias: "Electrical" },
    ]
    const [domainStatus, setDomainStatus] = useState("Mechanical")

    const getAllCoursesSection = async () => {
        try {
            const res = await axios.get(API_SETTINGS.GET_ALL_COURSES);
            const resData = res.data.sort((a, b) => {
                if (a.type < b.type) return -1
                return a.type > b.type ? 1 : 0
            })
            resData.forEach(element => {
                let ratingArray = [];
                for (let i = 0; i < 5; i++) {
                    if (i <= element['rating'] - 1) {
                        ratingArray.push('fa-solid fa-star text-warning');
                    } else {
                        ratingArray.push('fa-regular fa-star');
                    }
                }
                element['ratings'] = ratingArray
            });
            setCoursesData(resData)
        } catch (error) { }
    }

    const switchDomains = async (domain) => {
        setDomainStatus(domain)
        const res = await axios.get(`${API_SETTINGS.GET_COURSES_BY_DOMAIN}?domain=${domain}&limit=4`);
        const resData = res.data.sort((a, b) => {
            if (a.type < b.type) return -1
            return a.type > b.type ? 1 : 0
        })
        resData.forEach(element => {
            let ratingArray = [];
            for (let i = 0; i < 5; i++) {
                if (i <= element['rating'] - 1) {
                    ratingArray.push('fa-solid fa-star text-warning');
                } else {
                    ratingArray.push('fa-regular fa-star');
                }
            }
            element['ratings'] = ratingArray
        });
        setCoursesData(resData)
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getAllCoursesSection()
    }, [])
    return (
        <section className="courses" id='courses'>
            <div className="header-hight"></div>
            <div className="container">
                <div className="section-title">
                    <h2 className=''>Our <span className='text-danger'>Courses</span></h2>
                    <p className=''>Carefully designed for “freshers & working professionals”. Get rich content at an affordable price, Outstanding Industry expert support!</p>
                </div>

                <div className="row g-2">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-12 px-2">
                        <div className="domain-button">
                            {domains.map((domain) => (
                                <button type='button' className={`btn btn btn-light m-1 ${domain.name === domainStatus ? 'active' : ''}`} key={domain.name} onClick={() => switchDomains(domain.name)}>{domain.alias}</button>
                            ))}
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-12 px-2">
                        <div className="row g-2">
                            {CoursesData.map((course) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12" key={course._id}>
                                    <div className="card courses-card border">
                                        <div className="card-body">
                                            <div className="row g-2">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <div className="row g-2">
                                                        <div className="col-xl-3 col-lg-3 col-md-12 col-12">
                                                            <Link to={'course/' + course._id}>
                                                                <div className="courses-card-image border">
                                                                    <img src={course.image} alt="" onError={onImageError} />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-xl-9 col-lg-9 col-md-12 col-12">
                                                            <small className='courses-card-text-type'>{course.type}</small>
                                                            <Link to={'course/' + course._id}>
                                                                <h4 className='courses-card-text-title'>{course.title}</h4>
                                                            </Link>
                                                            <h6 className='mb-1'>
                                                                <span className="me-2">
                                                                    {course?.ratings?.map((rate, ind) => (
                                                                        <i className={rate} key={ind}></i>
                                                                    ))}
                                                                </span>
                                                                <span className='me-2'>|</span>
                                                                <span className='text-muted'><i className="fa-solid fa-hourglass-half me-2"></i>{course.duration}</span>
                                                            </h6>
                                                            <small className='fw-500 app-secondary-color'>{course.domain} Course</small>
                                                        </div>
                                                    </div>
                                                    {/* <div className="mt-2 mx-auto text-center">
                                                            <Link to={'course/' + course._id}>
                                                                <button className='btn btn-secondary btn-sm courses-card-button'>View Details</button>
                                                            </Link>
                                                        </div> */}
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <div className="courses-card-text">
                                                        <div className='courses-card-text-description'>{course.short_desc}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="col-12 text-end">
                                <Link to={`courses?domain=${domainStatus}`}>
                                    <button type="button" className="btn btn-primary app-primary-color-bg fw-500 border-0 mt-3">Explore More Courses<i className="fa-solid fa-angles-right ms-2"></i></button>
                                    {/* <h6 className='m-2'>Explore More Courses<i className="fa-solid fa-angles-right ms-2"></i></h6> */}
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Courses