import axios from 'axios'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import loginImage from '../assets/img/5243319.png'
import headerLogo from '../assets/img/logo.png'
import { API_SETTINGS } from '../settings/settings'

const SignUp = () => {
    const signupInputs = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
    }
    const [signupForm, setSignupForm] = useState(signupInputs)
    const [formValidation, setFormValidation] = useState(signupInputs)

    const navigate = useNavigate()

    const handleChange = (event) => {
        setSignupForm({
            ...signupForm,
            [event.target.name]: event.target.value
        });
        setFormValidation({
            ...formValidation,
            [event.target.name]: ""
        });
    }

    const isMobile = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSignupForm({
                ...signupForm,
                [event.target.name]: event.target.value
            });
            setFormValidation({
                ...formValidation,
                [event.target.name]: ""
            });
        }
    }

    const createUser = async (e) => {
        e.preventDefault();
        if (signupForm.first_name.length < 5) {
            setFormValidation({
                ...formValidation,
                first_name: "Invalid first name",
            })
            return
        }
        if (signupForm.last_name.length < 5) {
            setFormValidation({
                ...formValidation,
                last_name: "Invalid last name",
            })
            return
        }
        if (signupForm.email.length < 5) {
            setFormValidation({
                ...formValidation,
                email: "Invalid email",
            })
            return
        }
        if (signupForm.mobile.length !== 10) {
            setFormValidation({
                ...formValidation,
                mobile: "Invalid mobile",
            })
            return
        }
        if (signupForm.first_name.length > 3 && signupForm.last_name.length > 3 && validator.isEmail(signupForm.email) && signupForm.mobile.length > 5) {
            try {
                const res = await axios.post(API_SETTINGS.SIGN_UP, signupForm, { headers: { "Content-Type": "application/json", }, },);
                toast.success(res.data.message)
                if (res.status === 201) {
                    setSignupForm({
                        first_name: "",
                        last_name: "",
                        email: "",
                        mobile: "",
                    })
                    navigate(res.data.redirect_url)
                }
            } catch (error) {
                toast.error(error.response.data.message, { allowHtml: true })
            }
        } else {
            toast.error('Oops! Check the details you have entered!', { duration: 2000 })
        }
    }

    return (
        <section className="signup">
            <div className="container">
                <div className="card signup-card shadow-sm">
                    <div className="card-body p-4">
                        <div className="row g-0">
                            <div className="col d-none d-md-flex justify-content-center align-items-center">
                                <div className="login-image">
                                    <img src={loginImage} alt="" />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                                <div className="row">
                                    <div className="col-6 text-start d-flex align-items-center">
                                        <Link to={"/"}>
                                            <h6 className='text-secondary cursor-pointer m-0'><i className="fa-solid fa-caret-left me-1"></i>Back to Home</h6>
                                        </Link>
                                    </div>
                                    <div className="col-6 text-end">
                                        <h6 className='lh-30px m-0'><Link to={'/sign-in'} className='bg-warning px-2 py-1 text-black rounded'>Sign In<i className="fa-solid fa-caret-right ms-1"></i></Link></h6>
                                    </div>
                                </div>
                                <div className="mb-4 text-center">
                                    <div className="signup-card-logo">
                                        <img src={headerLogo} alt="" />
                                    </div>
                                    <h2 className='signup-card-title'>Signo Venture</h2>
                                </div>
                                <form action="" method="post" onSubmit={createUser}>
                                    <div className="signup-card-form">
                                        <div className="row g-2">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                                <div className="mb-2">
                                                    <label htmlFor="first_name" className="form-label fw-500">First Name<span className='text-danger ms-1'>*</span></label>
                                                    <input type="text" className={formValidation.first_name ? 'form-control fw-500 border-danger' : 'form-control fw-500'} name='first_name' id="first_name" placeholder="First Name" value={signupForm.first_name} onChange={handleChange} required autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                                <div className="mb-2">
                                                    <label htmlFor="last_name" className="form-label fw-500">Last Name<span className='text-danger ms-1'>*</span></label>
                                                    <input type="text" className={formValidation.last_name ? 'form-control fw-500 border-danger' : 'form-control fw-500'} name='last_name' id="last_name" placeholder="Last Name" value={signupForm.last_name} onChange={handleChange} required autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label htmlFor="email" className="form-label fw-500">Email<span className='text-danger ms-1'>*</span></label>
                                                    <input type="email" className={formValidation.email ? 'form-control fw-500 border-danger' : 'form-control fw-500'} name='email' id="email" placeholder="example@email.com" value={signupForm.email} onChange={handleChange} required autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label htmlFor="mobile" className="form-label fw-500">Mobile<span className='text-danger ms-1'>*</span></label>
                                                    <input type="tel" className={formValidation.mobile ? 'form-control fw-500 border-danger' : 'form-control fw-500'} name='mobile' id="mobile" placeholder="Mobile Number" value={signupForm.mobile} min="10" max="10" maxLength="10" onChange={isMobile} required autoComplete='off' />
                                                </div>
                                            </div>
                                            {/* <div className="col d-flex align-items-center">
                                                <h6 className='lh-30px'>Already have an account? <Link to={'/sign-in'} className='bg-warning px-2 py-1 text-black rounded'>Sign In</Link></h6>
                                            </div> */}
                                            <div className="col-12 text-end">
                                                <div className="text-end">
                                                    <button className='btn btn-success fw-500' type='submit'><i className="fa-regular fa-circle-check me-2"></i>Sign Up</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignUp
