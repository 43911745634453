import React from 'react'
import BaseHeader from './components/BaseHeader/BaseHeader'

const Layout = (Component) => ({ ...Props }) => {
    return (
        <>
            <BaseHeader></BaseHeader>
            <Component {...Props} />{}
        </>
    )
}

export default Layout