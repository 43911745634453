import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API_SETTINGS } from '../settings/settings'

const BaseFooter = () => {
  const [year, setYear] = useState(new Date().getFullYear())

  const [footer, setFooter] = useState({
    address: "",
    contacts: [],
    social_links: []
  })

  const [viewCounter, setViewCounter] = useState({
    uniq: 0,
    views: 0
  })

  const getAllContacts = async () => {
    try {
      const res = await axios.get(API_SETTINGS.GET_CONTACTS);
      setFooter(res.data)
    } catch (error) { }
  }

  const getAllViews = async () => {
    try {
      const res = await axios.get(API_SETTINGS.VIEW_COUNTERS);
      setViewCounter(res.data)
    } catch (error) { }
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  useEffect(() => {
    getAllContacts()
    getAllViews()
    setYear(new Date().getFullYear())
  }, [])

  return (
    <section className='footer'>
      <footer>
        <div className="container">
          <div className="pt-5 pb-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 text-center text-md-start text-lg-start">
                <h6 className='footer-title'>Address :</h6>
                <p className='fw-500 m-2'>{footer.address}</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 text-center text-md-start text-lg-start">
                <h6 className='footer-title'>Contacts :</h6>
                <ul className='footer-contacts mx-0 px-0'>
                  {footer.contacts.map((contact, index) => (
                    <li key={index}>
                      <a href={contact.link} target={contact.target}><i className={contact.icon}></i>{contact.content}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 text-center text-md-start text-lg-start">
                <h6 className='footer-title'>Follow Us :</h6>
                <ul className='footer-social-links mx-0 px-0'>
                  {footer.social_links.map((social, index) => (
                    <li key={index}>
                      <a href={social.link} target={social.target} className='btn btn-outline-secondary'><i className={social.icon}></i></a>
                    </li>
                  ))}
                </ul>
                <div className="text-center text-md-start text-lg-start">
                  <h6 className='footer-title'>Visits : <span className='px-2 py-1 bg-warning rounded'>{zeroPad(viewCounter.views, 4)}</span></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>Copyright &copy;</span>
          <span className='mx-1'>{year}</span>
          <span>| All Rights Reserved</span>
        </div>
      </footer>
    </section>
  )
}

export default BaseFooter