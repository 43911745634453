import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_SETTINGS } from '../settings/settings';
import FreeSection from './FreeSection';

const CareerPath = () => {
  const [careers, setCareers] = useState([])
  const getAllCareers = async () => {
    try {
      const res = await axios.get(API_SETTINGS.GET_ALL_CAREERS);
      setCareers(res.data)
    } catch (error) { }
  }

  useEffect(() => {
    getAllCareers()
  }, [])

  return (
    <section className='careerpath' id='careerpath'>
      <div className="container">
        <div className="header-hight"></div>
        <div className="section-title">
          <h2 className=''>Career<span className='text-danger ms-1'>Path</span></h2>
          <p className="">The Signoventure team comprises of talented and passionate design engineers who has the thought of sharing the knowledge and showing the path leading to the jobs in core sector.</p>
        </div>

        <div className="careerpath-content">
          <div className="row g-2">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="row g-2">
                {careers.map((career) => (
                  <div className="col-12" key={career._id}>
                    <div className="card career-path-card">
                      <div className="card-body">
                        <div className="row g-0">
                          <div className="col-auto d-flex justify-content-center align-items-center">
                            <div className="career-path-icon">
                              <i className={career.icon}></i>
                            </div>
                          </div>
                          <div className="col">
                            <div className="career-content">
                              <small className='text-muted fw-500'>{career.type}</small>
                              <a href={career.ref_link}>
                                <h5 className='career-title'>{career.title}</h5>
                              </a>
                              <p className='career-description'>{career.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <FreeSection></FreeSection>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerPath