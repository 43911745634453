import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getUserCourses } from '../../services/services';
import no_image from '../../assets/img/no_image_2.png'
import { Link } from 'react-router-dom';
export const Courses = () => {
    const [userCourses, setUserCourses] = useState()

    const getUserCourse = async () => {
        const resData = await getUserCourses();
        setUserCourses(resData.data.data)
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getUserCourse()
    }, [])

    return (
        <section className="dashboard-courses">
            <div className="row g-2">
                {userCourses?.map((course) => (
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12" key={course._id}>
                        <div className="card border-0 overflow-hidden">
                            <div className="card-body p-0 border-bottom">
                                <div className="courses-image">
                                    <img src={course?.image} alt="" onError={onImageError} />
                                </div>
                            </div>
                            <div className="card-body pb-0">
                                <h5>{course.title}</h5>
                                <div className="courses-short-desc">
                                    <p>{course.short_desc}</p>
                                </div>
                            </div>
                            <div className="card-body px-4">
                                <div className="row g-2 fw-500">
                                    <div className="col-md-6 col-12 text-muted">
                                        {course.domain}
                                    </div>
                                    <div className="col-md-6 col-12 text-end">
                                        <Link to={`course/${course._id}`}><i className="fa-solid fa-up-right-from-square me-2"></i>View Detais</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
