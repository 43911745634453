import React, { useEffect, useState } from "react";
import { getUserProfile } from "../../services/services";
import profileImage from '../../assets/img/Avatar_01.png'
import * as moment from 'moment'
import { Link } from 'react-router-dom';
import Profile from "./Profile";
import { Courses } from "./Courses";
const Dashboard = () => {
  const [userDetails, setUserDetails] = useState();
  const [isProfilePlaceholder, setIsProfilePlaceholder] = useState(true)
  const [selectedTab, setSeletedTab] = useState(sessionStorage.getItem("tab") || "profile")
  const dashBoradItems = [
    {
      title: "Profile",
      icon: "fa-solid fa-user-shield",
      link: "profile",
    },
    {
      title: "Courses",
      icon: "fa-solid fa-book",
      link: "courses",
    },
    {
      title: "Certificates",
      icon: "fa-solid fa-certificate",
      link: "certificates",
    },
    {
      title: "Challenges",
      icon: "fa-solid fa-user-shield",
      link: "challenges",
    },
  ]

  const onImageError = (e) => {
    e.target.src = profileImage
  }

  const getUserDetails = async () => {
    try {
      const scopes = [
        "first_name",
        "last_name",
        "email",
        "mobile",
        "date_of_birth",
        "gender",
        "bio",
        "image",
      ];
      setIsProfilePlaceholder(true)
      const resData = await getUserProfile(scopes);
      setIsProfilePlaceholder(false)
      console.log("resData", resData.data);
      setUserDetails(resData.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClickTabSwitch = (link) => {
    sessionStorage.setItem("tab", link)
    setSeletedTab(link)
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <section className="dashborad">
      <div className="height-80"></div>
      <div className="container">
        <div className="card border-0">
          <div className="card-body">
            <div className="row g-2">
              <div className="col-xl col-lg col-md-12 col-12">
                <div className="row g-2">
                  <div className="col-auto">
                    <div className="profile-image border">
                      {isProfilePlaceholder &&
                        <div className="placeholder-glow">
                          <span className="placeholder"></span>
                        </div>
                      }
                      {!isProfilePlaceholder &&
                        <img src={userDetails?.image} alt="" onError={onImageError} />
                      }
                    </div>
                  </div>
                  <div className="col">
                    <div className="ms-2">
                      {isProfilePlaceholder &&
                        <h5 className="card-title placeholder-glow"><span className="placeholder col-3"></span></h5>
                      }
                      {!isProfilePlaceholder &&
                        <h4 className="fw-600"> {userDetails?.last_name + " " + userDetails?.first_name} </h4>
                      }
                      <div className="text-muted">
                        <h6><i className="fa-solid fa-file-shield me-2 app-secondary-color"></i> {userDetails?.status ? userDetails?.status : "Student"} </h6>
                        <h6><i className="fa-solid fa-calendar-day me-2 app-secondary-color"></i> Joined On -{" "} {userDetails?.created_at ? moment(new Date(userDetails?.created_at)).format("MMMM YYYY") : "----"} </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 text-secondary">
                    <div className="m-2">
                      <label htmlFor="" className="app-secondary-color fw-500" > Bio </label>
                      <div className="pe-3">
                        {isProfilePlaceholder &&
                          <div className="placeholder-glow">
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-6"></span>
                          </div>
                        }
                        {!isProfilePlaceholder &&
                          <p className="text-justify">{userDetails?.bio}</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="card rounded-1rem border-info">
                  <div className="card-header border-0 text-center bg-white">
                    <h6 className='app-secondary-color fw-500 mt-2 mb-0'>Dashboard</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-2">
                      {dashBoradItems.map((item) => (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-6 p-1" key={item.link}>
                          <Link onClick={() => onClickTabSwitch(item.link)}>
                            <div className="dashborad-item">
                              <div className="icon-box">
                                <i className={item.icon}></i>
                              </div>
                              <h6 className="title">{item.title}</h6>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {selectedTab === 'profile' &&
            <Profile />
          }
          {selectedTab === 'courses' &&
            <Courses />
          }
        </div>
      </div>
      <div className="height-100"></div>
    </section>
  );
};

export default Dashboard;
